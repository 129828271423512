<template>
  <div id="email-verify">
    <v-card class="email-verify-card" elevation="2">
      <v-toolbar class="email-verify-card__toolbar" flat>
        <v-toolbar-title> {{ $t("Auth.EmailVerificationScreenHeader") }} </v-toolbar-title>
      </v-toolbar>

      <v-divider />

      <v-card-text>
        <template v-if="!errorCode">
          <v-alert text outlined color="success" icon="mdi-account-check-outline">
            <template v-if="!isAlreadyVerified">
              {{ $t("Auth.EmailVerificationScreenSuccess") }}
            </template>
            <template v-else>{{ $t("Auth.UserVerifiedAlready") }}</template>
          </v-alert>

          <v-btn v-if="!user.id" :to="{ name: 'login' }" color="primary" depressed block>
            {{ $t("Auth.SignIn") }}
          </v-btn>
          <v-btn v-else :to="{ name: 'dashboard' }" color="primary" depressed block>
            {{ $t("GlobalUI.Dashboard") }}
          </v-btn>
        </template>
        <template v-else>
          <v-alert text outlined color="deep-orange" icon="mdi-fire">
            {{ $t("Auth.EmailVerificationScreenFailed") }}
          </v-alert>

          <v-btn :to="{ name: 'verification.resend' }" color="primary" depressed block>
            {{ $t("Auth.ResendVerLink") }}
          </v-btn>
        </template>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";

import { API_URL } from "../../../config";
import { mapGetters } from "vuex";

export default {
  name: "Verify",

  layout: "auth",

  metaInfo() {
    return { title: this.$t("Auth.EmailVerificationScreenHeader") };
  },

  async beforeRouteEnter(to, from, next) {
    try {
      const {
        data: { isVerified, isAlreadyVerified, emailVerifiedAt, token },
      } = await axios.post(`${API_URL}/email/verify/${to.params.token}`, null, {
        config: { showNotify: false },
      });

      next(async (vm) => {
        if (isVerified && emailVerifiedAt && token) {
          // Save token
          await vm.$store.dispatch("auth/saveToken", {
            token: token,
            remember: true,
          });

          // Fetch the user
          await vm.$store.dispatch("auth/fetchUser");
          // Fetch the app settings
          await vm.$store.dispatch("app/fetchSettings");

          // autoredirect to dashboard
          vm.redirectTimeout = setTimeout(() => {
            vm.$router.push({ name: "dashboard" });
          }, 3 * 1000);
        }

        vm.isVerified = isVerified;
        vm.isAlreadyVerified = isAlreadyVerified || false;
      });
    } catch (err) {
      if (err.response) {
        const { data } = err.response;
        next((vm) => (vm.errorCode = data.code));
      } else {
        console.error(err);
      }
    }
  },

  computed: mapGetters({
    user: "auth/user",
  }),

  beforeDestroy() {
    if (this.redirectTimeout) clearTimeout(this.redirectTimeout);
  },

  data() {
    return {
      isVerified: false,
      isAlreadyVerified: false,

      redirectTimeout: null,

      errorCode: null,
    };
  },
};
</script>
